import { default as energy_45green_45practices1uPuuo1pQ1Meta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue?macro=true";
import { default as fun_45part_45journeyPSfStgGWKUMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue?macro=true";
import { default as homesafe_45colorado_45master_45builderyAeY3kaXIAMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue?macro=true";
import { default as indexYgAxrMjqX3Meta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45teambWS1dtvFbtMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as preferred_45lenderb7gX4hzdFCMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue?macro=true";
import { default as realtor_45resourcesPzR7y3KHSAMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue?macro=true";
import { default as _91post_93bmY3weh2PdMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexqX2fQ6XWqOMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as index68VLy4rkiCMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93eppUEOdmDCMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexBreVtshNPYMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as ask_45a_45questionuIbKFCVywXMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunitiesLeRmAWAeTPMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faqgtFw1KQADhMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourcesvZzNMB6ew4Meta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexBqYgacQgQ3Meta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locationsDh0XH2wmv8Meta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as current_45offersDMRwrRUsuOMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexJCDQdcLhnrMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indexBQ9v7Uq0oYMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/events/index.vue?macro=true";
import { default as indexngCqvy9jtzMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as index762LUJsIKMMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue?macro=true";
import { default as indexFfUXXh5WtnMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue?macro=true";
import { default as index8TBZugyXUIMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue?macro=true";
import { default as indexbyIo9rMuBsMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/index.vue?macro=true";
import { default as knolls_45hoa_45informationmreLFO7kubMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue?macro=true";
import { default as land_45acquisitionayjuJSYxETMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as brents_45placeAj2JlHCT4aMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue?macro=true";
import { default as experience_45lower_45maintenance8IPkaAuBWuMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue?macro=true";
import { default as _91testimonial_937sCH1yGNn1Meta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as indexnsLiziPEpoMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexAr0DpUChtDMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as whats_45lifefullnessbRw0plTlfZMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue?macro=true";
import { default as _91_46_46_46slug_93TZ0BcHahLdMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as my_45favoriteseDy0TFsEEsMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45searchHo2a7Lj86DMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as easyhouse9JhtfWFe00Meta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededitionRWM7tuSLC4Meta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottageRp0h6ulUt6Meta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as index7NX7QfeX3UMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as our_45neighborhoodsuXsi1mXeY2Meta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue?macro=true";
import { default as partner_45with_45usJG91ekU1rhMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue?macro=true";
import { default as privacytermsDxCyN4AvjDMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as sandbox0uKPvYArMgMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as _91_46_46_46slug_93GRmpdPRsNSMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue?macro=true";
import { default as component_45stubfm2NtTYfoLMeta } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubfm2NtTYfoL } from "/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: energy_45green_45practices1uPuuo1pQ1Meta?.name ?? "about-us-energy-green-practices",
    path: energy_45green_45practices1uPuuo1pQ1Meta?.path ?? "/about-us/energy-green-practices",
    meta: energy_45green_45practices1uPuuo1pQ1Meta || {},
    alias: energy_45green_45practices1uPuuo1pQ1Meta?.alias || [],
    redirect: energy_45green_45practices1uPuuo1pQ1Meta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue").then(m => m.default || m)
  },
  {
    name: fun_45part_45journeyPSfStgGWKUMeta?.name ?? "about-us-fun-part-journey",
    path: fun_45part_45journeyPSfStgGWKUMeta?.path ?? "/about-us/fun-part-journey",
    meta: fun_45part_45journeyPSfStgGWKUMeta || {},
    alias: fun_45part_45journeyPSfStgGWKUMeta?.alias || [],
    redirect: fun_45part_45journeyPSfStgGWKUMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue").then(m => m.default || m)
  },
  {
    name: homesafe_45colorado_45master_45builderyAeY3kaXIAMeta?.name ?? "about-us-homesafe-colorado-master-builder",
    path: homesafe_45colorado_45master_45builderyAeY3kaXIAMeta?.path ?? "/about-us/homesafe-colorado-master-builder",
    meta: homesafe_45colorado_45master_45builderyAeY3kaXIAMeta || {},
    alias: homesafe_45colorado_45master_45builderyAeY3kaXIAMeta?.alias || [],
    redirect: homesafe_45colorado_45master_45builderyAeY3kaXIAMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue").then(m => m.default || m)
  },
  {
    name: indexYgAxrMjqX3Meta?.name ?? "about-us",
    path: indexYgAxrMjqX3Meta?.path ?? "/about-us",
    meta: indexYgAxrMjqX3Meta || {},
    alias: indexYgAxrMjqX3Meta?.alias || [],
    redirect: indexYgAxrMjqX3Meta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45teambWS1dtvFbtMeta?.name ?? "about-us-our-team",
    path: our_45teambWS1dtvFbtMeta?.path ?? "/about-us/our-team",
    meta: our_45teambWS1dtvFbtMeta || {},
    alias: our_45teambWS1dtvFbtMeta?.alias || [],
    redirect: our_45teambWS1dtvFbtMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: preferred_45lenderb7gX4hzdFCMeta?.name ?? "about-us-preferred-lender",
    path: preferred_45lenderb7gX4hzdFCMeta?.path ?? "/about-us/preferred-lender",
    meta: preferred_45lenderb7gX4hzdFCMeta || {},
    alias: preferred_45lenderb7gX4hzdFCMeta?.alias || [],
    redirect: preferred_45lenderb7gX4hzdFCMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue").then(m => m.default || m)
  },
  {
    name: realtor_45resourcesPzR7y3KHSAMeta?.name ?? "about-us-realtor-resources",
    path: realtor_45resourcesPzR7y3KHSAMeta?.path ?? "/about-us/realtor-resources",
    meta: realtor_45resourcesPzR7y3KHSAMeta || {},
    alias: realtor_45resourcesPzR7y3KHSAMeta?.alias || [],
    redirect: realtor_45resourcesPzR7y3KHSAMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue").then(m => m.default || m)
  },
  {
    name: _91post_93bmY3weh2PdMeta?.name ?? "blog-post",
    path: _91post_93bmY3weh2PdMeta?.path ?? "/blog/:post()",
    meta: _91post_93bmY3weh2PdMeta || {},
    alias: _91post_93bmY3weh2PdMeta?.alias || [],
    redirect: _91post_93bmY3weh2PdMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: indexqX2fQ6XWqOMeta?.name ?? "blog-category-category",
    path: indexqX2fQ6XWqOMeta?.path ?? "/blog/category/:category()",
    meta: indexqX2fQ6XWqOMeta || {},
    alias: indexqX2fQ6XWqOMeta?.alias || [],
    redirect: indexqX2fQ6XWqOMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index68VLy4rkiCMeta?.name ?? "blog",
    path: index68VLy4rkiCMeta?.path ?? "/blog",
    meta: index68VLy4rkiCMeta || {},
    alias: index68VLy4rkiCMeta?.alias || [],
    redirect: index68VLy4rkiCMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93eppUEOdmDCMeta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_93eppUEOdmDCMeta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93eppUEOdmDCMeta || {},
    alias: _91_46_46_46slug_93eppUEOdmDCMeta?.alias || [],
    redirect: _91_46_46_46slug_93eppUEOdmDCMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexBreVtshNPYMeta?.name ?? "campaigns",
    path: indexBreVtshNPYMeta?.path ?? "/campaigns",
    meta: indexBreVtshNPYMeta || {},
    alias: indexBreVtshNPYMeta?.alias || [],
    redirect: indexBreVtshNPYMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: ask_45a_45questionuIbKFCVywXMeta?.name ?? "contact-us-ask-a-question",
    path: ask_45a_45questionuIbKFCVywXMeta?.path ?? "/contact-us/ask-a-question",
    meta: ask_45a_45questionuIbKFCVywXMeta || {},
    alias: ask_45a_45questionuIbKFCVywXMeta?.alias || [],
    redirect: ask_45a_45questionuIbKFCVywXMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunitiesLeRmAWAeTPMeta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunitiesLeRmAWAeTPMeta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunitiesLeRmAWAeTPMeta || {},
    alias: career_45opportunitiesLeRmAWAeTPMeta?.alias || [],
    redirect: career_45opportunitiesLeRmAWAeTPMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: faqgtFw1KQADhMeta?.name ?? "contact-us-faq",
    path: faqgtFw1KQADhMeta?.path ?? "/contact-us/faq",
    meta: faqgtFw1KQADhMeta || {},
    alias: faqgtFw1KQADhMeta?.alias || [],
    redirect: faqgtFw1KQADhMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: homeowner_45resourcesvZzNMB6ew4Meta?.name ?? "contact-us-homeowner-resources",
    path: homeowner_45resourcesvZzNMB6ew4Meta?.path ?? "/contact-us/homeowner-resources",
    meta: homeowner_45resourcesvZzNMB6ew4Meta || {},
    alias: homeowner_45resourcesvZzNMB6ew4Meta?.alias || [],
    redirect: homeowner_45resourcesvZzNMB6ew4Meta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: indexBqYgacQgQ3Meta?.name ?? "contact-us",
    path: indexBqYgacQgQ3Meta?.path ?? "/contact-us",
    meta: indexBqYgacQgQ3Meta || {},
    alias: indexBqYgacQgQ3Meta?.alias || [],
    redirect: indexBqYgacQgQ3Meta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45locationsDh0XH2wmv8Meta?.name ?? "contact-us-our-locations",
    path: our_45locationsDh0XH2wmv8Meta?.path ?? "/contact-us/our-locations",
    meta: our_45locationsDh0XH2wmv8Meta || {},
    alias: our_45locationsDh0XH2wmv8Meta?.alias || [],
    redirect: our_45locationsDh0XH2wmv8Meta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: current_45offersDMRwrRUsuOMeta?.name ?? "current-offers",
    path: current_45offersDMRwrRUsuOMeta?.path ?? "/current-offers",
    meta: current_45offersDMRwrRUsuOMeta || {},
    alias: current_45offersDMRwrRUsuOMeta?.alias || [],
    redirect: current_45offersDMRwrRUsuOMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: indexJCDQdcLhnrMeta?.name ?? "events-event",
    path: indexJCDQdcLhnrMeta?.path ?? "/events/:event()",
    meta: indexJCDQdcLhnrMeta || {},
    alias: indexJCDQdcLhnrMeta?.alias || [],
    redirect: indexJCDQdcLhnrMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexBQ9v7Uq0oYMeta?.name ?? "events",
    path: indexBQ9v7Uq0oYMeta?.path ?? "/events",
    meta: indexBQ9v7Uq0oYMeta || {},
    alias: indexBQ9v7Uq0oYMeta?.alias || [],
    redirect: indexBQ9v7Uq0oYMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexngCqvy9jtzMeta?.name ?? "homes-location-community",
    path: indexngCqvy9jtzMeta?.path ?? "/homes/:location()/:community()",
    meta: indexngCqvy9jtzMeta || {},
    alias: indexngCqvy9jtzMeta?.alias || [],
    redirect: indexngCqvy9jtzMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: index762LUJsIKMMeta?.name ?? "homes-location-community-models-model",
    path: index762LUJsIKMMeta?.path ?? "/homes/:location()/:community()/models/:model()",
    meta: index762LUJsIKMMeta || {},
    alias: index762LUJsIKMMeta?.alias || [],
    redirect: index762LUJsIKMMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: indexFfUXXh5WtnMeta?.name ?? "homes-location-community-plans-plan",
    path: indexFfUXXh5WtnMeta?.path ?? "/homes/:location()/:community()/plans/:plan()",
    meta: indexFfUXXh5WtnMeta || {},
    alias: indexFfUXXh5WtnMeta?.alias || [],
    redirect: indexFfUXXh5WtnMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: index8TBZugyXUIMeta?.name ?? "homes-location-community-qmi-home",
    path: index8TBZugyXUIMeta?.path ?? "/homes/:location()/:community()/qmi/:home()",
    meta: index8TBZugyXUIMeta || {},
    alias: index8TBZugyXUIMeta?.alias || [],
    redirect: index8TBZugyXUIMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbyIo9rMuBsMeta?.name ?? "index",
    path: indexbyIo9rMuBsMeta?.path ?? "/",
    meta: indexbyIo9rMuBsMeta || {},
    alias: indexbyIo9rMuBsMeta?.alias || [],
    redirect: indexbyIo9rMuBsMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: knolls_45hoa_45informationmreLFO7kubMeta?.name ?? "knolls-hoa-information",
    path: knolls_45hoa_45informationmreLFO7kubMeta?.path ?? "/knolls-hoa-information",
    meta: knolls_45hoa_45informationmreLFO7kubMeta || {},
    alias: knolls_45hoa_45informationmreLFO7kubMeta?.alias || [],
    redirect: knolls_45hoa_45informationmreLFO7kubMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue").then(m => m.default || m)
  },
  {
    name: land_45acquisitionayjuJSYxETMeta?.name ?? "land-acquisition",
    path: land_45acquisitionayjuJSYxETMeta?.path ?? "/land-acquisition",
    meta: land_45acquisitionayjuJSYxETMeta || {},
    alias: land_45acquisitionayjuJSYxETMeta?.alias || [],
    redirect: land_45acquisitionayjuJSYxETMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: brents_45placeAj2JlHCT4aMeta?.name ?? "lifestyle-brents-place",
    path: brents_45placeAj2JlHCT4aMeta?.path ?? "/lifestyle/brents-place",
    meta: brents_45placeAj2JlHCT4aMeta || {},
    alias: brents_45placeAj2JlHCT4aMeta?.alias || [],
    redirect: brents_45placeAj2JlHCT4aMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue").then(m => m.default || m)
  },
  {
    name: experience_45lower_45maintenance8IPkaAuBWuMeta?.name ?? "lifestyle-experience-lower-maintenance",
    path: experience_45lower_45maintenance8IPkaAuBWuMeta?.path ?? "/lifestyle/experience-lower-maintenance",
    meta: experience_45lower_45maintenance8IPkaAuBWuMeta || {},
    alias: experience_45lower_45maintenance8IPkaAuBWuMeta?.alias || [],
    redirect: experience_45lower_45maintenance8IPkaAuBWuMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_937sCH1yGNn1Meta?.name ?? "lifestyle-reviews-testimonial",
    path: _91testimonial_937sCH1yGNn1Meta?.path ?? "/lifestyle/reviews/:testimonial()",
    meta: _91testimonial_937sCH1yGNn1Meta || {},
    alias: _91testimonial_937sCH1yGNn1Meta?.alias || [],
    redirect: _91testimonial_937sCH1yGNn1Meta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: indexnsLiziPEpoMeta?.name ?? "lifestyle-reviews-category-category",
    path: indexnsLiziPEpoMeta?.path ?? "/lifestyle/reviews/category/:category()",
    meta: indexnsLiziPEpoMeta || {},
    alias: indexnsLiziPEpoMeta?.alias || [],
    redirect: indexnsLiziPEpoMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexAr0DpUChtDMeta?.name ?? "lifestyle-reviews",
    path: indexAr0DpUChtDMeta?.path ?? "/lifestyle/reviews",
    meta: indexAr0DpUChtDMeta || {},
    alias: indexAr0DpUChtDMeta?.alias || [],
    redirect: indexAr0DpUChtDMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: whats_45lifefullnessbRw0plTlfZMeta?.name ?? "lifestyle-whats-lifefullness",
    path: whats_45lifefullnessbRw0plTlfZMeta?.path ?? "/lifestyle/whats-lifefullness",
    meta: whats_45lifefullnessbRw0plTlfZMeta || {},
    alias: whats_45lifefullnessbRw0plTlfZMeta?.alias || [],
    redirect: whats_45lifefullnessbRw0plTlfZMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93TZ0BcHahLdMeta?.name ?? "marshallfire-slug",
    path: _91_46_46_46slug_93TZ0BcHahLdMeta?.path ?? "/marshallfire/:slug(.*)*",
    meta: _91_46_46_46slug_93TZ0BcHahLdMeta || {},
    alias: _91_46_46_46slug_93TZ0BcHahLdMeta?.alias || [],
    redirect: _91_46_46_46slug_93TZ0BcHahLdMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: my_45favoriteseDy0TFsEEsMeta?.name ?? "my-favorites",
    path: my_45favoriteseDy0TFsEEsMeta?.path ?? "/my-favorites",
    meta: my_45favoriteseDy0TFsEEsMeta || {},
    alias: my_45favoriteseDy0TFsEEsMeta?.alias || [],
    redirect: my_45favoriteseDy0TFsEEsMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: new_45home_45searchHo2a7Lj86DMeta?.name ?? "new-home-search",
    path: new_45home_45searchHo2a7Lj86DMeta?.path ?? "/new-home-search",
    meta: new_45home_45searchHo2a7Lj86DMeta || {},
    alias: new_45home_45searchHo2a7Lj86DMeta?.alias || [],
    redirect: new_45home_45searchHo2a7Lj86DMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: easyhouse9JhtfWFe00Meta?.name ?? "our-homes-home-collections-easyhouse",
    path: easyhouse9JhtfWFe00Meta?.path ?? "/our-homes/home-collections/easyhouse",
    meta: easyhouse9JhtfWFe00Meta || {},
    alias: easyhouse9JhtfWFe00Meta?.alias || [],
    redirect: easyhouse9JhtfWFe00Meta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: limitededitionRWM7tuSLC4Meta?.name ?? "our-homes-home-collections-limitededition",
    path: limitededitionRWM7tuSLC4Meta?.path ?? "/our-homes/home-collections/limitededition",
    meta: limitededitionRWM7tuSLC4Meta || {},
    alias: limitededitionRWM7tuSLC4Meta?.alias || [],
    redirect: limitededitionRWM7tuSLC4Meta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: wee_45cottageRp0h6ulUt6Meta?.name ?? "our-homes-home-collections-wee-cottage",
    path: wee_45cottageRp0h6ulUt6Meta?.path ?? "/our-homes/home-collections/wee-cottage",
    meta: wee_45cottageRp0h6ulUt6Meta || {},
    alias: wee_45cottageRp0h6ulUt6Meta?.alias || [],
    redirect: wee_45cottageRp0h6ulUt6Meta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: index7NX7QfeX3UMeta?.name ?? "our-homes",
    path: index7NX7QfeX3UMeta?.path ?? "/our-homes",
    meta: index7NX7QfeX3UMeta || {},
    alias: index7NX7QfeX3UMeta?.alias || [],
    redirect: index7NX7QfeX3UMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: our_45neighborhoodsuXsi1mXeY2Meta?.name ?? "our-neighborhoods",
    path: our_45neighborhoodsuXsi1mXeY2Meta?.path ?? "/our-neighborhoods",
    meta: our_45neighborhoodsuXsi1mXeY2Meta || {},
    alias: our_45neighborhoodsuXsi1mXeY2Meta?.alias || [],
    redirect: our_45neighborhoodsuXsi1mXeY2Meta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue").then(m => m.default || m)
  },
  {
    name: partner_45with_45usJG91ekU1rhMeta?.name ?? "partner-with-us",
    path: partner_45with_45usJG91ekU1rhMeta?.path ?? "/partner-with-us",
    meta: partner_45with_45usJG91ekU1rhMeta || {},
    alias: partner_45with_45usJG91ekU1rhMeta?.alias || [],
    redirect: partner_45with_45usJG91ekU1rhMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue").then(m => m.default || m)
  },
  {
    name: privacytermsDxCyN4AvjDMeta?.name ?? "privacyterms",
    path: privacytermsDxCyN4AvjDMeta?.path ?? "/privacyterms",
    meta: privacytermsDxCyN4AvjDMeta || {},
    alias: privacytermsDxCyN4AvjDMeta?.alias || [],
    redirect: privacytermsDxCyN4AvjDMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: sandbox0uKPvYArMgMeta?.name ?? "sandbox",
    path: sandbox0uKPvYArMgMeta?.path ?? "/sandbox",
    meta: sandbox0uKPvYArMgMeta || {},
    alias: sandbox0uKPvYArMgMeta?.alias || [],
    redirect: sandbox0uKPvYArMgMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93GRmpdPRsNSMeta?.name ?? "videos-slug",
    path: _91_46_46_46slug_93GRmpdPRsNSMeta?.path ?? "/videos/:slug(.*)*",
    meta: _91_46_46_46slug_93GRmpdPRsNSMeta || {},
    alias: _91_46_46_46slug_93GRmpdPRsNSMeta?.alias || [],
    redirect: _91_46_46_46slug_93GRmpdPRsNSMeta?.redirect,
    component: () => import("/codebuild/output/src4080240645/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/about-us/get-our-brochure/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/about-us/in-the-news/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/about-us/realtor-program/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/about-us/what-we-do/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/about-us/who-we-are/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/build-with-us/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/career-opportunities/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/career-opportunities/administrative-assistant-2/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/career-opportunities/director-of-warranty-services/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/career-opportunities/land-development-entitlements-coordinator/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/contact-us/ask-a-question/thank-you/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/contact-us/warranty-services/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/email-signup/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/get-updates/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/get-updates/thank-you/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/how-to-video-library/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/lifestyle/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/locations/lanterns-at-rock-creek/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/locations/silver-leaf/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/locations/wee-cottage-at-north-end-80238/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/locations/wee-cottage-at-rogers-farm/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/locations/west-edge-at-colliers-hill/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/login/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/lost-password/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/maintenance-items/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/marshall-fire-response/thank-you-loi/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/neighborhoods-map-test/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/organism-page/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/our-homes/home-collections/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/our-homes/home-collections/lanterns-series/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/our-homes/home-collections/opportunities-series/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/our-homes/photo-gallery/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/our-homes/photo-gallery/gourmet-kitchens/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/our-homes/photo-gallery/home-offices/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/our-homes/photo-gallery/living-rooms/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/our-homes/photo-gallery/outdoor-living-spaces/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/our-homes/photo-gallery/relaxing-master-suites/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/register/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/reset-password/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/resources-lower-maintenance/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/style-guide/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  },
  {
    name: component_45stubfm2NtTYfoLMeta?.name ?? undefined,
    path: component_45stubfm2NtTYfoLMeta?.path ?? "/warranty-service-request/",
    meta: component_45stubfm2NtTYfoLMeta || {},
    alias: component_45stubfm2NtTYfoLMeta?.alias || [],
    redirect: component_45stubfm2NtTYfoLMeta?.redirect,
    component: component_45stubfm2NtTYfoL
  }
]